<template>
  <div
    :class="{ fullsize: isFullSize, 'card-container': true }"
    :data-index="index"
    @mouseover="isFlipped = true"
    @mouseleave="isFlipped = false"
  >
    <div :class="{ flipped: isFlipped, 'flip-container': true }">
      <!-- front content -->
      <div :class="[card_class, 'front']">
        <!--img class="card-img" :src="'static/assets/' + url" /-->
        <div class="image-container">
          <img class="card-img" :src="image" />
        </div>
        <div
          :class="{
            'card-edition': true,
            'plat-edition': is_plat,
          }"
        >
          <span>{{ edition_label }}</span>
        </div>
        <div class="card-name">{{ name }}<br />{{ cset }}</div>
        <div class="bottom-text">
          {{ parseInt(unlock_czxp).toLocaleString() }}
        </div>
        <div class="bottom-right-corner">
          {{ level }}
        </div>
        <div class="card-booster-shop card-booster-shop-circle" />
        <div class="top-right-corner" :style="{ backgroundColor: activeColor }">
          <b-icon-lightning-fill
            v-if="in_store == 'Booster'"
            class="card-booster-shop-icon"
            scale="1.3"
          />
          <b-icon-tag-fill
            v-if="in_store == 'Store'"
            class="card-booster-shop-icon"
            scale="1.3"
          />
        </div>
        <div v-if="is_owned && used_in_cardsets" class="owned-label">OWNED</div>
        <div v-else-if="is_minted && used_in_cardsets" class="not-owned-label">
          NOT OWNED
        </div>
        <div
          v-else-if="!is_minted && used_in_cardsets"
          class="not-minted-label"
        >
          NEVER MINTED
        </div>
      </div>
      <div class="back card-bg card-bg-back-bsc">
        <div class="back-container">
          <div
            v-if="!!id && !used_in_cardsets"
            class="card-txt-black font-weight-bold"
            title="Copy link to token"
            @click="onCopyLink"
          >
            <h5 id="share">
              Token #{{ id }} <b-icon-link-45deg></b-icon-link-45deg>
            </h5>
          </div>
          <div class="card-txt-black">
            <span class="attribute-name font-weight-bold">Cost:</span>
            <span>{{ cost }}</span>
          </div>
          <div class="card-txt-black">
            <span class="attribute-name font-weight-bold">Mint Reward:</span>
            <span>{{ parseInt(buy_czxp).toLocaleString() }}</span>
          </div>
          <div class="card-txt-black">
            <span class="attribute-name font-weight-bold">Sacrifice ZOOM:</span>
            <span>{{ parseInt(sacrifice_czxp).toLocaleString() }}</span>
          </div>
          <p class="text-center">
            <img src="./assets/moonriver.svg" class="mr-logo" />
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BIconLink45deg } from "bootstrap-vue";
import { showSuccessToast } from "../util/showToast";

export default {
  name: "OwnedCardContent",
  props: [
    "id",
    "type_id",
    "name",
    "image",
    "edition_current",
    "edition_total",
    "cset",
    "unlock_czxp",
    "level",
    "cost",
    "buy_czxp",
    "sacrifice_czxp",
    "card_class",
    "in_store",
    "card_owned",
    "is_single_card_view",
    "index",
    "observer",
    "is_plat",
    "used_in_cardsets",
    "is_minted",
    "is_owned",
  ],
  components: {
    BIconLink45deg,
  },
  data() {
    return {
      isFlipped: false,
    };
  },
  computed: {
    activeColor() {
      if (this.in_store == "Store") {
        return "#FFA500"; //orange
      } else {
        return "#F8FF02"; //yellow
      }
    },
    edition_label() {
      if (this.used_in_cardsets) {
        if (this.edition_total === "0") {
          return "Unlimited";
        } else {
          return `Edition Total: ${this.edition_total}`;
        }
      }

      if (!this.card_owned) {
        return this.edition_current + "/" + this.edition_total;
      } else {
        if (this.edition_total === 0) {
          return "#" + this.edition_current;
        } else {
          return "#" + this.edition_current + " of " + this.edition_total;
        }
      }
    },
    isFullSize() {
      return this.is_single_card_view;
    },
  },
  mounted() {
    if (this.observer) {
      this.observer.observe(this.$el);
    }
  },
  methods: {
    getTokenLink(tokenId) {
      let url;
      if (window.location.host === "moonbase.zoombies.world") {
        url = "https://moonbase.zoombies.world";
      } else if (process.env.NODE_ENV === "development") {
        url = "localhost:8080";
      } else {
        url = "https://movr.zoombies.world";
      }

      return `${url}/view/${tokenId}`;
    },
    onCopyLink() {
      navigator.clipboard.writeText(this.getTokenLink(this.id));
      showSuccessToast(this, "Link to Token copied to clipboard");
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
/* Layout */

.card-bg {
  display: inline-block;
  width: 100%;
  height: 100%;
  text-align: center;
  margin-bottom: 3px;
  padding-bottom: 4px;
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: 0px;
}

.card-bg-6 {
  background-image: url("https://zoombies.world/images/dapp/zoombies_card_common_brown.svg");
}

.card-bg-5 {
  background-image: url("https://zoombies.world/images/dapp/zoombies_card_uncommon_blue.svg");
}

.card-bg-4 {
  background-image: url("https://zoombies.world/images/dapp/zoombies_card_rare_red.svg");
}

.card-bg-3 {
  background-image: url("https://zoombies.world/images/dapp/zoombies_card_epic_purple.svg");
}

.card-bg-2 {
  background-image: url("https://zoombies.world/images/dapp/zoombies_card_platinum.svg");
}

.card-bg-1 {
  background-image: url("https://zoombies.world/images/dapp/zoombies_card_diamond.svg");
}

.card-bg-back-bsc {
  background-image: url("https://zoombies.world/images/dapp/zoombies_card_back.svg");
  padding: 20px;
}

.image-container {
  position: absolute;
  top: 15%;
  left: 10%;
  right: 10%;
  height: 45%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.card-img {
  width: 80%;
}

.top-right-corner {
  position: absolute;
  top: 4%;
  right: 0;
  width: 19%;
  height: 12%;
  border-radius: 50%;
  border: 3px solid black;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bottom-right-corner {
  position: absolute;
  bottom: 1.5%;
  right: 0;
  width: 19%;
  height: 12%;
  display: flex;
  justify-content: center;
  align-items: center;

  font-weight: bold;
  color: #fff;
}

.card-edition {
  position: absolute;
  top: 62%;
  bottom: 32%;
  left: 10%;
  right: 10%;
  color: #ddd;
  font-weight: 700;
}

.plat-edition {
  color: black;
}

.card-name {
  font-weight: bold;
  position: absolute;
  left: 5%;
  right: 5%;
  top: 70%;
  bottom: 13%;
  color: black;

  display: flex;
  justify-content: center;
  align-items: center;
}

.bottom-text {
  position: absolute;
  color: #fff;
  font-weight: bold;
  left: 21%;
  right: 21%;
  bottom: 4.5%;
  height: 7%;
  text-align: left;
  display: flex;
  align-items: center;
}

.card-txt-black {
  color: #000;
}

/* Back of card stuff */
.card-txt-white {
  color: #fff;
}

.back-container {
  position: absolute;
  top: 35%;
  bottom: 16%;
  left: 8%;
  right: 7%;
  background-color: rgba(200, 200, 200, 0.7);
  padding: 5%;

  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

/** Card **/

/* entire container, keeps perspective */

.card-container {
  margin: 0;
  height: 410px;
  width: 260px;

  font-size: 16px;

  perspective: 600px;
}

@media screen and (max-width: 1000px) {
  .card-container {
    height: calc(0.55 * 410px);
    width: calc(0.55 * 260px);
    font-size: 10px;
  }

  #share {
    font-size: 12px !important;
    margin: 0;
  }

  .fullsize {
    height: 410px !important;
    width: 260px !important;
    font-size: 16px !important;
  }
}

/* flip the pane when hovered */
.flipped {
  transform: rotateY(180deg);
}

.flip-container {
  transition: transform 0.6s;
  transform-style: preserve-3d;

  position: relative;
}
.flip-container,
.front,
.back {
  width: 100%;
  height: 100%;
}

@media (hover: hover) and (pointer: fine) {
  .card-container:hover .flipped {
    transform: rotateY(180deg);
  }
}

/* hide back of pane during swap */
.front,
.back {
  backface-visibility: hidden;

  position: absolute;
  top: 0;
  left: 0;
}

/* front pane, placed above back */
.front {
  z-index: 2;
  /* for firefox 31 */
  transform: rotateY(0deg);
}

/* back, initially hidden pane */
.back {
  transform: rotateY(180deg);
  position: relative;
}

.attribute-name {
  padding-right: 5px;
}

#share {
  cursor: pointer;

  &:hover {
    color: #0645ad;
  }
}

.card-booster-shop-icon {
  color: #000000;
}

.not-minted-label,
.owned-label,
.not-owned-label {
  position: absolute;
  top: 17%;
  border-radius: 2px;
  border: 2px solid black;
  width: 100%;
  height: 15%;
  font-size: 1.5rem;
  color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.8;
}

@media screen and (max-width: 768px) {
  .not-minted-label,
  .owned-label,
  .not-owned-label {
    font-size: 1rem;
  }
}

.owned-label {
  background-color: #7ef4f6;
}

.not-minted-label {
  background-color: #6c757d4f;
}

.not-owned-label {
  background-color: #f566e2;
}

@media screen and (max-width: 768px) {
  .mr-logo {
    max-width: 50%;
    position: relative;
    top: 36px;
  }
}
@media screen and (min-width: 768px) {
  .mr-logo {
    max-width: 50%;
    position: relative;
    top: 36px;
  }
}
@media screen and (min-width: 1024px) {
  .mr-logo {
    max-width: 50%;
    position: relative;
    top: 65px;
  }
}
</style>
